import * as React from 'react'
import { Global } from '@emotion/react'
import { globalStyles, Wrapper } from './styles'

export interface LayoutProps {
  children: any
}

export function Layout({ children }: LayoutProps) {
  return (
    <Wrapper>
      <Global styles={globalStyles} />
      {children}
    </Wrapper>
  )
}
